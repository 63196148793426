import React, { useEffect, useState } from 'react'
import Select from "react-select"
import PlacementHeader from './Components/TopBar'
import Sidebar from './Components/Sidebar5'
import './PlacementPortal.scss'
import momentjs from 'moment-timezone'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { placementSidebarMap, JOB_STATUS, STATIC_ROUNDS, OFFER_STATUS, BOARD_DROPDOWN, BACHELORS_DROPDOWN } from "../../constants/constant";
import PDFReader from "../../Components/PDFReader/PDFReader";

import {
  fetchPlacementCourse,
  changeLearnerStatus,
  sendJobApplyReminderToStudents,
  updateOfferStatus,
  getPlacementProfilePreview
} from '../../actions/jobAction.js'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'

const configs = require('../../config/configs.js')

const ViewLearnerProfile = () => {
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [resumeURL, setResumeURL] = useState("");
  const [openResumeView, setOpenResumeView] = useState(false)
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [selectedLearner, setSelectedLearner] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [numOpenings, setNumOpenings] = useState(0)
  const [numEligible, setNumEligible] = useState(0)
  const [numApplied, setNumApplied] = useState(0)
  const [numRejected, setNumRejected] = useState(0)
  const [numShared, setNumShared] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [updatedAt, setUpdatedAt] = useState('')
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [basicDetails, setBasicDetails] = useState({
    ccod: "",
    name: "",
    email: "",
    num: "",
    aboutme: "",
    gender: "",
    uid: "",
    ppic: "",
  });
  const [educationDetails, setEducationDetails] = useState({
    matric: {
      school: "",
      board: "",
      perc: "",
      year: "",
    },
    inter: {
      school: "",
      board: "",
      perc: "",
      year: "",
    },
    grad: {
      college: "",
      degree: "",
      perc: "",
      year: "",
    },
    post_grad: {
      college: "",
      degree: "",
      perc: "",
      year: "",
    },
  });
  const [professionalDetails, setProfessionalDetails] = useState({
    job_status: {
      curr_working: true,
      total_exp: 0,
      curr_designation: "",
      curr_org: "",
      notice_period: 0,
      last_salary: "",
      curr_loc: "",
    },
    preferences: {
      relocate: true,
      night_shift: false,
      intern: false,
      contract: false,
      loc_pref: [],
    },
    links: {
      linkedin: "",
      github: "",
      kaggle: "",
      icr_agreement: "",
    },
  });
  const [resume, setResume] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(2);
  const [isDefaultCollapsed, setIsDefaultCollapsed] = useState(true);
  const [expanded, setExpanded] = useState({});
  const [account, setAccount] = useState({});
  const [profile, setProfile] = useState({});
  const [percCompleted, setPercCompleted] = useState(0);
  const pathname = window.location.pathname
  const pathParts = pathname.split('/');
  const student_aid = pathParts.slice(-1)[0];
  const crs_pg_id = pathParts.slice(-2)[0];

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const toggleCollapse = () => {
    setIsDefaultCollapsed(!isDefaultCollapsed);
  };

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const changeJobType = (jobType) => {
    console.log("changed job type")
    setSelectedJobType(jobType);
  };

  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const handleResumeModalOpen = (aid) => {

    const selectedResume = data.find((item) => item.aid === aid);

    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.learner_resume);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.learner_resume);
    }
    setOpenResumeView(true)
  };

  const exportLearnerData = (crs_pg_id, student_aid) => {
    const cookies = new Cookies()
    let isGrade = 1
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${configs.routes.BASE_URL_DEVELOPMENT
      }/cfpanelPlacement/exp_learnerdata`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: crs_pg_id,
        student_aid: student_aid,
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
        window.alert('File Downloaded successfully')
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const downloadFile = (res) => {
    const contentDisposition = res.headers['content-disposition']
    let fileName = contentDisposition.split(';')[1].split('=')[1]

    console.log('File name : ', fileName)
    fileName = fileName.replaceAll('"', '')
    console.log('File name : ', fileName)

    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  useEffect(() => {
    console.log("useEffect ran");
    getPlacementProfilePreview(crs_pg_id, student_aid)
      .then(function (res) {
        console.log(res, ":response data");
        setAccount(res.account);
        setProfile(res.profile)
        if (res && res.lastFilledBlockIndex) {
          setLastFilledBlockIndex(res.lastFilledBlockIndex);
        }
        if (res && res.learnerInfo) {
          let basicdetail = res.learnerInfo?.["basic-details"] ?? {};
          let educationaldetail =
            res.learnerInfo?.["educational-details"] ?? {};
          let professionaldetail =
            res.learnerInfo?.["professional-details"] ?? {};

          setBasicDetails({
            ccod: basicdetail?.ccod,
            name: basicdetail?.name,
            email: basicdetail?.email,
            num: basicdetail?.num,
            uid: basicdetail?.uid,
            ppic: basicdetail?.ppic,
            aboutme: basicdetail?.aboutme,
            gender: basicdetail?.gender,
          });
          setEducationDetails({
            matric: {
              school: educationaldetail?.matric?.school,
              board: educationaldetail?.matric?.board,
              perc: educationaldetail?.matric?.perc,
              year: educationaldetail?.matric?.year,
            },
            inter: {
              school: educationaldetail?.inter?.school,
              board: educationaldetail?.inter?.board,
              perc: educationaldetail?.inter?.perc,
              year: educationaldetail?.inter?.year,
            },
            grad: {
              college: educationaldetail?.grad?.college,
              degree: educationaldetail?.grad?.degree,
              perc: educationaldetail?.grad?.perc,
              year: educationaldetail?.grad?.year,
            },
            post_grad: {
              college: educationaldetail?.post_grad?.college,
              degree: educationaldetail?.post_grad?.degree,
              perc: educationaldetail?.post_grad?.perc,
              year: educationaldetail?.post_grad?.year,
            },
          });
          setProfessionalDetails({
            working: professionaldetail?.working,
            notice_period: professionaldetail?.notice_period,
            last_salary: professionaldetail?.last_salary,
            default_jobexp: {
              curr_working: professionaldetail?.default_jobexp?.curr_working,
              company_name: professionaldetail?.default_jobexp?.company_name,
              job_title: professionaldetail?.default_jobexp?.job_title,
              job_location: professionaldetail?.default_jobexp?.job_location,
              employment_type:
                professionaldetail?.default_jobexp?.employment_type,
              year: professionaldetail?.default_jobexp?.year,
              month: professionaldetail?.default_jobexp?.month,
            },
            additional_jobexp: professionaldetail?.additional_jobexp,
            total_experience: {
              year: professionaldetail?.total_experience?.year,
              month: professionaldetail?.total_experience?.month,
            },
            preferences: {
              relocate: professionaldetail?.preferences?.relocate,
              night_shift: professionaldetail?.preferences?.night_shift,
              intern: professionaldetail?.preferences?.intern,
              contract: professionaldetail?.preferences?.contract,
              loc_pref: professionaldetail?.preferences?.loc_pref,
            },
            links: {
              linkedin: professionaldetail?.links?.linkedin,
              github: professionaldetail?.links?.github,
              kaggle: professionaldetail?.links?.kaggle,
              icr_agreement: professionaldetail?.links?.icr_agreement,
            },
          });
          setPercCompleted(res.perc_completed);
        }
        if (res && res.learnerResume) {
          setResume(res.learnerResume);
          console.log({ learnerResume: res.learnerResume });
        }
        if (res && res.jobsData) {

          console.log({ jobsData: res.jobsData })
          setJobsData(res.jobsData);
          let appliedCount = res.jobsData.filter(job => job.job_app_static_status > 1).length
          let rejectedCount = res.jobsData.filter(job => job.job_app_static_status > 5).length
          let sharedCount = res.jobsData.filter(job => job.job_app_static_status > 0).length

          console.log({ sharedCount })
          setNumApplied(appliedCount)
          setNumRejected(rejectedCount)
          setNumShared(sharedCount)

        }
        if (res && res.lastUpdated) {
          setUpdatedAt(res.lastUpdated)
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
      <>
        <Sidebar />
        <div className="labs-main">
          <PlacementHeader breadCrumbs={[
            { name: "Career Services", link: "/" },
            { name: "Manage Learners", link: "/career-services/application-status" },
          ]}
            active={2} />
          <div className="labs-content">
            <div style={{ paddingBottom: '16px' }}>
              <Row>
                <div className="revenuemaincontainer-2">
                  <div className="revenuecontainer-2 selected-jobtype">
                    <h4>Total Opportunities Shared</h4>
                    <h2> {numShared > 0 ? numShared : 0}</h2>
                  </div>

                  <div className="revenuecontainer-2">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Opportunities Applied</h4>
                      <h4 className="tooltipl"></h4>
                    </div>
                    <h2> {numApplied > 0 ? numApplied : 0}</h2>
                  </div>
                  <div className="revenuecontainer-2">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Opportunities Rejected</h4>
                      <h4 className="tooltipl">
                      </h4>
                    </div>
                    <h2 style={{ color: "#DC3545" }}> {numRejected > 0 ? numRejected : 0}</h2>
                  </div>
                  <div className="revenuecontainer-2">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Opportunities Not Applied</h4>
                      <h4 className="tooltipl">
                      </h4>
                    </div>
                    <h2 style={{ color: "#DC3545" }}> {numShared > 0 ? numShared - numApplied : 0}</h2>
                  </div>
                </div>
              </Row>
              <Row>
                <Col md={2} className="labs-table-header">
                </Col>
                <Col md={2} className="labs-table-header">
                </Col>
                <Col md={2} className="labs-table-header">
                </Col>
                <Col md={2} >
                </Col>
                <Col md={2} >
                </Col>
                <Col md={2} >
                  <div className="row">
                    <button
                      onClick={() => exportLearnerData(crs_pg_id, student_aid)}
                      // className="labs-table-btn-4"
                      className="jobs-table-btn-6"
                    >
                      <img
                        src="https://webcdn.imarticus.org/Placement/tabler_file-export1.svg"
                      // style={{ minHeight: "40px",minWidth: "40px" }}
                      />
                      Export Report
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="group-db-content-wrapper-2">
              <div className="container-fluid dashboard-content">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-7 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row justify-content-left profile-bar">
                      <div className="col-md-2">
                        <div className="img-container-2">
                          <div className="full-space-image">
                            <img
                              className="profile-img"
                              src={profile.ppic || ''}
                              style={{ paddingRight: "7px", marginLeft: "10px" }}
                            ></img>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="col-md-11">
                          <h5 className="profile-preview-name">
                            {profile.nam}
                          </h5>
                        </div>
                        <div className="col-md-11">
                          <div
                            className="row profile-preview-fields "
                            style={{
                              // marginLeft: "15px",
                            }}
                          >
                            <div className="col-md-12 profile-updt-dtl">
                              <p className='prof-dtl-val'>Profile Completion: <strong>{`${percCompleted}%`}</strong></p>
                              <div className="row ignore-row-margin">
                                <p className='prof-dtl-val'>Profile Last Updated: <strong>{`${momentjs(updatedAt).format(
                                  "DD MMMM YYYY "
                                )}`}</strong></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row profile-preview-fields "
                          style={{
                            marginLeft: "15px",
                            borderTop: "1px solid #71748d",
                          }}
                        >
                          <div className="col-md-6 ">
                            <p><img
                              style={{ height: "16px", marginRight: "5px" }}
                              src={
                                "https://webcdn.imarticus.org/Placement/teenyicons_id-solid.svg"
                              }
                            />
                              {account.uid}</p>
                          </div>
                          <div className="col-md-6">
                            <div className="row ignore-row-margin">
                              <p><img
                                style={{ height: "16px", marginRight: "5px" }}
                                src={
                                  "https://webcdn.imarticus.org/Placement/ic_baseline-email.svg"
                                }
                              />{account.ep}</p>
                              {account.ep_vrfy && (
                                <img
                                  style={{ height: "16px", marginLeft: "10px" }}
                                  src={
                                    "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                  }
                                  alt={"Verified"}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row profile-preview-fields" style={{ marginLeft: "15px" }}>
                          <div className="col-md-6">
                            <div className="row ignore-row-margin profile-preview-fields ">
                              <p><img
                                style={{ height: "16px", marginRight: "5px", marginLeft: "15px" }}
                                src={
                                  "https://webcdn.imarticus.org/Placement/material-symbols_call.svg"
                                }
                              />{account.m ? account.m : '-'}</p>
                              {account.m && (
                                <img
                                  style={{ height: "16px", marginLeft: "10px" }}
                                  src={
                                    "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                  }
                                  alt={"Verified"}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 profile-preview-fields ">
                            <p><img
                              style={{ height: "16px", marginRight: "5px", marginLeft: "-15px" }}
                              src={
                                "https://webcdn.imarticus.org/Placement/mdi_timezone.svg"
                              }
                            />
                              {account.tz}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="group-db-content-wrapper">
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginBottom: "16px" }}>
                    <div className="justify-content-center ">
                      <div className="ps-main-content col-xl-12 col-lg-12 col-md-12 col-sm-12 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">Education</h4>
                          </li>
                          <br></br>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Class X
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Board Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BOARD_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.matric.board)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Percentage:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.matric.perc} %
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Class XII
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Board Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BOARD_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.matric.board)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row ">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Percentage:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.matric.perc} %
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Graduation/Diploma
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                College/University Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.college}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Degree Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BACHELORS_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.grad.degree)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Graduation Pass Out Year:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.year}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Percentage:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.perc} %
                              </h5>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginBottom: "16px" }}>
                    <div className="justify-content-center ">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">Job Details</h4>
                          </li>
                          <br></br>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Do you have any work
                                experience?(Full-time+Internship):
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.working === true ||
                                  professionalDetails.working === "true"
                                  ? "Yes"
                                  : professionalDetails.working === false ||
                                    professionalDetails.working === "false"
                                    ? "No"
                                    : "-"}
                              </h5>
                            </div>
                          </li>
                          {professionalDetails?.working &&
                            professionalDetails?.working === "true" && (
                              <>
                                <li className="no-wrap row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Total Experience:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.total_experience
                                        .year || 0}{" "}
                                      Years{" "}
                                      {professionalDetails.total_experience
                                        .month || 0}{" "}
                                      Months
                                    </h5>
                                  </div>
                                </li>
                                <li className="no-wrap row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Notice Period:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.notice_period} Months
                                    </h5>
                                  </div>
                                </li>
                                <li className="no-wrap row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Last Drawn Salary:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.last_salary} LPA
                                    </h5>
                                  </div>
                                </li>
                              </>
                            )}
                          {professionalDetails?.working &&
                            professionalDetails?.working === "true" && (
                              <>
                                <li
                                  className="row"
                                  style={{
                                    marginTop: "16px",
                                    width: "100%",
                                    background: "#EFF4F5",
                                    borderRadius: "4px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <li
                                    className="row"
                                    style={{
                                      marginTop: "16px",
                                      marginLeft: "16px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      cursor: "pointer",
                                    }}
                                    onClick={toggleCollapse}
                                  >
                                    <h6 className="profile-prev-job-exp-heading ">
                                      Job Experience 1
                                    </h6>
                                    {isDefaultCollapsed ? (
                                      <i
                                        className="icon fa fa-chevron-down"
                                        style={{
                                          fontSize: "small",
                                          paddingRight: "32px",
                                          paddingTop: "8px",
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="icon fa fa-chevron-up"
                                        style={{
                                          fontSize: "small",
                                          paddingRight: "32px",
                                          paddingTop: "8px",
                                        }}
                                      ></i>
                                    )}
                                  </li>
                                  {!isDefaultCollapsed && (
                                    <>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Company Name:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.company_name
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Job Title:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.job_title
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Job Location:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.job_location
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Employment Type:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.employment_type
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Experience:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.year
                                            }{" "}
                                            Years{" "}
                                            {
                                              professionalDetails.default_jobexp
                                                ?.month
                                            }{" "}
                                            Months
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="no-wrap row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          marginBottom: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading"
                                            htmlFor="thumbImg"
                                          >
                                            Currently working here:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {professionalDetails.default_jobexp
                                              ?.curr_working
                                              ? "Yes"
                                              : "No"}
                                          </h5>
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </li>

                                {professionalDetails.additional_jobexp &&
                                  professionalDetails.additional_jobexp.length >
                                  0 &&
                                  professionalDetails.additional_jobexp.map(
                                    (job, index) => (
                                      <li
                                        className="row"
                                        key={index}
                                        style={{
                                          marginTop: "16px",
                                          width: "100%",
                                          background: "#EFF4F5",
                                          borderRadius: "4px",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        <li
                                          className="row"
                                          style={{
                                            marginTop: "16px",
                                            marginLeft: "16px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => toggleExpand(index)}
                                        >
                                          <h6 className="profile-prev-job-exp-heading">
                                            Job Experience {index + 2}
                                          </h6>
                                          {expanded[index] ? (
                                            <i
                                              className="icon fa fa-chevron-up"
                                              style={{
                                                fontSize: "small",
                                                paddingRight: "32px",
                                                paddingTop: "8px",
                                              }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="icon fa fa-chevron-down"
                                              style={{
                                                fontSize: "small",
                                                paddingRight: "32px",
                                                paddingTop: "8px",
                                              }}
                                            ></i>
                                          )}
                                        </li>
                                        {expanded[index] && (
                                          <>
                                            <li
                                              className="no-wrap row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading">
                                                  Company Name:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.company_name || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="no-wrap row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading">
                                                  Job Title:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.job_title || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="no-wrap row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading">
                                                  Job Location:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.job_location || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="no-wrap row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading">
                                                  Employment Type:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.employment_type || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="no-wrap row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                marginBottom: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading">
                                                  Experience:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.year} Years {job.month}{" "}
                                                  Months
                                                </h5>
                                              </div>
                                            </li>
                                          </>
                                        )}
                                      </li>
                                    )
                                  )}
                              </>
                            )}
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open to Relocate:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.relocate ===
                                  true ||
                                  professionalDetails.preferences.relocate === "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.relocate ===
                                    false ||
                                    professionalDetails.preferences.relocate ===
                                    "false"
                                    ? "No"
                                    : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Relocation Locations:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.loc_pref.length > 0
                                  ? professionalDetails.preferences.loc_pref.map(loc => loc.label).join(', ')
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Night Shifts/Rotational:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.night_shift ===
                                  true ||
                                  professionalDetails.preferences.night_shift ===
                                  "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.night_shift ===
                                    false ||
                                    professionalDetails.preferences.night_shift ===
                                    "false"
                                    ? "No"
                                    : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Internships?:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.intern === true ||
                                  professionalDetails.preferences.intern === "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.intern ===
                                    false ||
                                    professionalDetails.preferences.intern ===
                                    "false"
                                    ? "No"
                                    : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Contractual Roles:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.contract ===
                                  true ||
                                  professionalDetails.preferences.contract === "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.contract ===
                                    false ||
                                    professionalDetails.preferences.contract ===
                                    "false"
                                    ? "No"
                                    : "-"}
                              </h5>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginBottom: "16px" }}>
                    <div className="justify-content-center ">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">Links</h4>
                          </li>
                          <br></br>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Github Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.github || "-"} */}
                                {professionalDetails.links.github ? (
                                  <a
                                    href={professionalDetails.links.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.github}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Kaggle Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.kaggle || "-"} */}
                                {professionalDetails.links.kaggle ? (
                                  <a
                                    href={professionalDetails.links.kaggle}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.kaggle}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                LinkedIn Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.linkedin || "-"} */}
                                {professionalDetails.links.linkedin ? (
                                  <a
                                    href={professionalDetails.links.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.linkedin}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-heading"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Signed ICR Agreement:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                className="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.links.icr_agreement ? (
                                  <a
                                    href={professionalDetails.links.icr_agreement}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {"Agreement Link"}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginBottom: "16px" }}>
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">Resume</h4>
                          </li>
                          <br></br>
                          {resume
                            .filter((file) => file.resume_type === 2)
                            .map((file, index) => (
                              <div key={index}>
                                <div className="resume-div drop-zone">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                                      />
                                    </div>
                                    <div className="col-md-7">
                                      <h5 className="resume-heading">
                                        Resume {index + 1}
                                      </h5>
                                      <p>
                                        {momentjs(file.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2">
                                      <img
                                        src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                        className="as-dl-logo-job-3"
                                        onClick={() =>
                                          previewResumeClick(file.resume_url)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            ))}
                          {resume
                            .filter((file) => file.resume_type === 1)
                            .map((file, index) => (
                              <div key={index}>
                                <div className="resume-div drop-zone">
                                  <div className="row">
                                    <div className="col-md-2">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                                      />
                                    </div>
                                    <div className="col-md-7">
                                      <h5 className="resume-heading">
                                        Resume {index + 1}
                                      </h5>
                                      <p>
                                        {momentjs(file.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2">
                                      <a
                                        href={file.resume_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                          className="as-dl-logo-job-3"
                                          alt="View Resume"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginBottom: "16px" }}>
                    <div className="justify-content-center ">
                      <div className="ps-main-content col-xl-12 col-lg-12 col-md-12 col-sm-12 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Job History
                            </h4>
                          </li>
                          <br></br>
                          <div className="row job-display-profile">
                            <div
                              className="col-md-3"
                              onClick={() => changeJobType(2)}
                            >
                              <span
                                className={
                                  selectedJobType === 2
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                {" "}
                                Applied Jobs
                              </span>
                            </div>
                            <div
                              className="col-md-4"
                              onClick={() => changeJobType(1)}
                            >
                              <span
                                className={
                                  selectedJobType === 1
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                Not Applied/Rejected Jobs
                              </span>
                            </div>
                            <div
                              className="col-md-3"
                              onClick={() => changeJobType(4)}
                            >
                              <span
                                className={
                                  selectedJobType === 4
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                Placed Jobs
                              </span>
                            </div>
                          </div>
                          <div className="row job-dtl-heading" style={{ marginTop: "20px" }}>
                            <div className="col-md-2">
                              <span className="profile-job">Job ID</span>
                            </div>
                            <div className="col-md-3">
                              <span className="profile-job">Company Name</span>
                            </div>
                            <div className="col-md-3">
                              <span className="profile-job">Job Role</span>
                            </div>
                            <div className="col-md-2">
                              <span className="profile-job">Applied On</span>
                            </div>
                            <div className="col-md-2">
                              <span className="profile-job">Job Status</span>
                            </div>
                          </div>
                          {jobsData
                            .filter(
                              (job) => {
                                if (job.job_status >= 3) {
                                  if (selectedJobType === 1) {
                                    if (
                                      job.job_app_static_status === 6 ||
                                      job.job_app_static_status === 1 ||
                                      job.job_app_static_status === 7
                                    ) {
                                      return true;
                                    }
                                  } else if (selectedJobType === 2) {
                                    if (
                                      job.job_app_static_status >= 2
                                    ) {
                                      return true;
                                    }
                                  } else if (selectedJobType === 4) {
                                    if (
                                      job.job_app_static_status === 4
                                    ) {
                                      return true;
                                    }
                                  }
                                }
                              }
                              // job.job_status >= 3 &&
                              // (job.job_app_static_status >= selectedJobType ||
                              //     (selectedJobType === 1 &&
                              //     (job.job_app_static_status === 6 ||
                              //         job.job_app_static_status === 1 ||
                              //         job.job_app_static_status === 7)) ||
                              //         (selectedJobType === 4 &&
                              //           (job.job_app_static_status === 4))
                              //         )
                            )
                            .map((job, index) => (
                              <div key={index} className="row job-dtl-heading" style={{ marginTop: "15px" }}>
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {job.job_code}
                                  </span>
                                </div>
                                <div className="col-md-3">
                                  <span className="profile-job-dsc">
                                    {job.company_name}
                                  </span>
                                </div>
                                <div className="col-md-3">
                                  <span className="profile-job-dsc">
                                    {job.job_title}
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {job.applied_on
                                      ? momentjs(job.applied_on).format(
                                        "DD-MM-YYYY"
                                      )
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {STATIC_ROUNDS[job.job_app_static_status]}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Modal isOpen={togglePDFDoc} className="resume-preview-modal" size={"md"} style={{ minwidth: "400px" }} centered>
          <ModalHeader
            className="resume-prev"
            style={{ paddingRight: "7px", width: "100%" }}
          >
            <li className="row">
              <div className="viewres-txt">
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: '700', marginLeft: "32px" }}>
                  <div>Resume Preview </div>
                </div>
                <div className='res-dl-btn ' style={{ marginRight: "32px" }}>
                  <img
                    src="https://webcdn.imarticus.org/Placement/Group926.svg"
                    style={{ paddingRight: "7px" }}
                    onClick={() => setTogglePDFDoc(false)}
                  />
                </div>
              </div>
            </li>
          </ModalHeader>
          <ModalBody
            style={{
              minHeight: "500px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <iframe
              src={resumeURL}
              style={{ width: "100%", height: "100%", border: "none", minHeight: "750px" }}
              frameBorder="0"
            />
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
        </Modal>
      </>
    </div>
  )
}


export default ViewLearnerProfile