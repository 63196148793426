import React, { useEffect, useState } from 'react'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import Sidebar2 from './Components/Sidebar2'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    FormGroup,
  } from 'reactstrap'
import { connect } from 'react-redux'  
import { COUNTRY_CODES } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import { history } from '../..'
import {
  savePlacementCriteria,
} from '../../actions/jobAction.js'
// import "froala-editor/js/froala_editor.pkgd.min.js";
// import "froala-editor/js/plugins.pkgd.min.js";
// import "froala-editor/js/plugins/font_size.min.js";
// import "froala-editor/js/third_party/embedly.min.js";
// import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/froala_editor.pkgd.min.css";
// import "froala-editor/css/third_party/embedly.min.css";


import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const configs = require('../../config/configs.js')




const AddJobCriteria = (props) => {
  const [requests, setRequests] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState(null)
  const [selectedShiftTiming , setSelectedShiftTiming] = useState({})
  const [locationDropdown, setLocationDropdown] = useState([])
  const [locations, setLocations] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [eligibleProducts, setEligibleProducts] = useState([])
  const [eligibleSkills, setEligibleSkills] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [alreadyAddedSkills, setAlreadyAddedSkills] = useState([]) 
  const [alreadyAddedLocation,setAlreadyAddedLocation] = useState('')
  const [addedInfo, setAddedInfo] = useState('') 
  const [selectedSkills, setSelectedSkills] = useState([]) 
  const [selectedProducts, setSelectedProducts] = useState([])  
  const [selectedGender, setSelectedGender] = useState([])
  const [selectedExperience, setSelectedExperience] = useState([])
  const [selectedGradYear, setSelectedGradYear] = useState([])
  const [shiftTiming, setShiftTiming] = useState([ { _id:0,name:'Morning Shift (09 AM - 06 PM)'},
    {_id:1,name:'Afternoon Shift (12 PM - 09 PM)'},
    {_id:2,name:'Evening Shift (03 PM - 12 AM)'},
    {_id:3, name: 'Night Shift (06 PM - 03 AM)'},
    {_id:4 , name:'Late Night Shift (09 PM - 06 AM)'},])
  const [genderAllowed, setGenderAllowed] = useState([])   
  const [experienceAllowed, setExperienceAllowed] = useState([])
  const [gradYear, setGradYears] = useState([])  
  const [data, setData] = useState([])
  const [isJobPublished, setIsJobPublished] = useState(false)
  const [createdCriteriaId,setCreatedCriteriaId] = useState('')
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]
  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelGeneric/get_all_csops_course_locations?aid=${cookies.get(
            'aid'
          )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            let resultDropdown = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
                resultDropdown.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                  value: tmp_data[d]._id,
                  label: tmp_data[d].name,
                })
            }}
            
            setLocationDropdown(resultDropdown)
            result.unshift({ name: 'All', _id: null })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()
    
    const getJobCriteriaData = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_placementcriteria_data?jobid=${jobid}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            console.log(res.data.data)
            let tmp_data =
              res.data.data && res.data.data
                ? res.data.data
                : []
            let result = []
            setEligibleProducts(tmp_data.placementCriteria.productCriteria)
            // setShiftTiming(tmp_data.placementCriteria.shiftCriteria)
            setGenderAllowed(tmp_data.placementCriteria.genderCriteria)
            setExperienceAllowed(tmp_data.placementCriteria.experienceCriteria)
            setGradYears(tmp_data.placementCriteria.graduationCriteria)
            setIsJobPublished(tmp_data.isJobPublished)

            if(tmp_data.alreadyCreatedCriteria.length > 0){

              const createdData = tmp_data.alreadyCreatedCriteria[0]
              const selectedGenderObjects = [];
              const selectedExperienceObjects = [];
              const selectedGradyearObjects = [];
              const selectedProductObjects = [];
              console.log({createdData:createdData})
              createdData.selected_gender.forEach(index => {
                console.log({index:index})
                const gender = tmp_data.placementCriteria.genderCriteria.find(gender => gender.value == index);
                console.log({gender:gender})
                if (gender) {
                  selectedGenderObjects.push(gender);
                }
              });
              createdData.selected_experience.forEach(index => {
                const gender = tmp_data.placementCriteria.experienceCriteria.find(gender => gender.value == index);
                if (gender) {
                  selectedExperienceObjects.push(gender);
                }
              });
              createdData.selected_gradyear.forEach(index => {
                const gender = tmp_data.placementCriteria.graduationCriteria.find(gender => gender.value == index);
                if (gender) {
                  selectedGradyearObjects.push(gender);
                }
              });
              createdData.selected_products.forEach(index => {
                const gender = tmp_data.placementCriteria.productCriteria.find(gender => gender.value == index);
                if (gender) {
                  selectedProductObjects.push(gender);
                }
              });
              const selectedShiftObj = shiftTiming.find(gender => gender._id == createdData.selected_shift);
              const selectedLocObj = tmp_data.allLocations.find(gender => gender._id == createdData.base_location);

              console.log({selectedShiftObj:selectedShiftObj})
              console.log({selectedLocObj:selectedLocObj})
              console.log(selectedLocObj.name)
              
              setCreatedCriteriaId(createdData._id)
              setSelectedGender(selectedGenderObjects)
              setSelectedExperience(selectedExperienceObjects)
              setSelectedGradYear(selectedGradyearObjects)
              setSelectedLocationDropdown({name:selectedLocObj.name,
              _id:selectedLocObj._id})
              setSelectedProducts(selectedProductObjects)
              setSelectedShiftTiming(selectedShiftObj)
              setAddedInfo(createdData.additional_info)
              setAlreadyAddedSkills(createdData.selected_skills)
              setAlreadyAddedLocation(createdData.base_location)
            }
            
          
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getJobCriteriaData()
  }, [refresh, page, rowsPerPage])

  useEffect( () => {
    const cookies = new Cookies()
    // setIsLoading(true)
    const getJobCriteriaData = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_product_skilltag_data`,
          method: 'POST',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: { selectedProducts },
        }
        Axios(getData)
          .then((res) => {
            console.log(res.data.data)
            let tmp_data =
              res.data.data && res.data.data
                ? res.data.data
                : []
            let result = []
            setEligibleSkills(tmp_data.skillTagCriteria)
            const selectedSkillsObj = [];
            if(tmp_data.skillTagCriteria.length > 0){
              alreadyAddedSkills.forEach(index => {
                const gender = tmp_data.skillTagCriteria.find(gender => gender.value == index);
                if (gender) {
                  selectedSkillsObj.push(gender);
                }
              });
              setSelectedSkills(selectedSkillsObj)
            }
            // setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getJobCriteriaData()

  }, [selectedProducts])


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const checkVisibility = (resource,resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  const handleModalClose = () => {
    // setOpenJobCreate(false)
    setSelectedLocationDropdown({})
  }
  const handleModelChange = (model) => {
    setAddedInfo(model);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const dropdownlocationChanged = (locationName, locationId) => {
    setSelectedLocationDropdown({
      name: locationName,
      _id: locationId,
    })
  }
  const shiftTimingChanged = (shiftName, shiftId) => {
    setSelectedShiftTiming({
      name: shiftName,
      _id: shiftId,
    })
    // setLocationId(locationId)
  }

  const handleSelectedProductChange = (e) => {
    const selectedProducts = e.map(option => ({ value: option.value,label: option.label }));
    console.log({selectedProducts:selectedProducts})
    setSelectedProducts(selectedProducts);
  }
  const handleSelectedSkillChange = (e) => {
    const selectedSkills = e.map(option => ({ value: option.value,label: option.label }));
    console.log({selectedSkills:selectedSkills})
    setSelectedSkills(selectedSkills);
  }

  const handleSelectedGenderChange = (e) => {
    const selectedGender = e.map(option => ({ value: option.value,label: option.label }));
    setSelectedGender(selectedGender);
  }

  const handleSelectedExperienceChange = (e) => {
    const selectedExperience = e.map(option => ({ value: option.value,label: option.label }));
    setSelectedExperience(selectedExperience);
  }

  const handleAdditionalInformationChange = (e) => {
    setAddedInfo(e.target.value);
  }

  const handleGradYearChange = (e) => {
    const selectedGradYear = e.map(option => ({ value: option.value,label: option.label }));
    setSelectedGradYear(selectedGradYear);
  }


  const handleSaveAndRedirect = async (jobid) => {
    try {
      await savePlacementCriteriaRedirect();
      
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  };

  const savePlacementCriteriaRedirect = async () => {

    if (
      selectedProducts.length == 0
    ) {
      window.alert("Please choose all Eligible Products");
      return;
    }
    
    // if (
    //   selectedSkills.length == 0
    // ) {
    //   window.alert("Please choose the required Skills");
    //   return;
    // }

    // if (
    //   selectedGradYear.length == 0
    // ) {
    //   window.alert("Please select Graduation Years");
    //   return;
    // }

    // if (
    //   selectedGender.length == 0
    // ) {
    //   window.alert("Please choose the Genders applicable");
    //   return;
    // }

    if (
      selectedExperience.length == 0
    ) {
      window.alert("Please choose the required experience");
      return;
    }

    setIsLoading(true)
    let { success, message } = await savePlacementCriteria({
      jobid,
      criteriaid: createdCriteriaId,
      products: selectedProducts,
      skillTags: selectedSkills,
      gradYear: selectedGradYear,
      gender: selectedGender,
      workex: selectedExperience,
      shiftTime: selectedShiftTiming,
      baseLoc: selectedlocationDropdown,
      additionalInfo: addedInfo,
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      // window.alert('Success')
      history.push(`/career-module/add-additional-questions/${jobid}`);
    }
    // load()
    setIsLoading(false)
  }

  const savePlacementCriteriaDraft = async () => {

    if (
      selectedProducts.length == 0
    ) {
      window.alert("Please choose all Eligible Products");
      return;
    }
    
    // if (
    //   selectedSkills.length == 0
    // ) {
    //   window.alert("Please choose the required Skills");
    //   return;
    // }

    // if (
    //   selectedGradYear.length == 0
    // ) {
    //   window.alert("Please select Graduation Years");
    //   return;
    // }

    // if (
    //   selectedGender.length == 0
    // ) {
    //   window.alert("Please choose the Genders applicable");
    //   return;
    // }

    // if (
    //   selectedExperience.length == 0
    // ) {
    //   window.alert("Please choose the required experience");
    //   return;
    // }

    setIsLoading(true)
    let { success, message } = await savePlacementCriteria({
      jobid,
      criteriaid: createdCriteriaId,
      products: selectedProducts,
      skillTags: selectedSkills,
      gradYear: selectedGradYear,
      gender: selectedGender,
      workex: selectedExperience,
      shiftTime: selectedShiftTiming,
      baseLoc: selectedlocationDropdown,
      additionalInfo: addedInfo,
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.alert('Success')
    }
    // load()
    setIsLoading(false)
  }
  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
    <>
      <div className="jobs-main">
        <TopBar2
        heading={'Create a Job'}
        />
        <div>
          <Sidebar2 active={2} jobid={jobid}/>
        </div>
        <div>
          <div className="jobs-header">
            <Row>
            <MiniHeader  breadCrumbs={[
              { name: "Career Services", link: "/career-services" },
              { name: "Job Postings", link: "/career-services" },
              ]}
              active={1}
              />
            </Row>
          </div>
          <div className="jobs-content">
          <div className="adques-all">
              <div className="adques-header">
                <Col md={9} className="jobs-table-header-2">
                  <p style={{ marginBottom : '10px' }}>Add Criteria</p>
                </Col>
                {/* <Col md={2}>
                  <Button className="jobs-table-btn-5"
                    onClick={() => (openJobCreate ? setOpenJobCreate(false) : setOpenJobCreate(true))}
                  >Add Questions +
                  </Button>
                </Col> */}
              </div>
            <div style={{ marginTop: '2rem' }}>
            </div>
            <div className='addcrit-content'>
              <li className="row">
              <div className="col-md-12">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  Select Product
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <FormGroup>
                 <Select
                  closeMenuOnSelect={false}
                  options={eligibleProducts}
                  placeholder="Select Product"
                  value={selectedProducts}
                  onChange={(event) => {
                    handleSelectedProductChange(event)
                  }}
                  isDisabled={!checkVisibility('43', 2) || isJobPublished}
                  isSearchable={true}
                  isMulti
                />
                </FormGroup>
              </div>
            </li>
            <li className='row'>
              <div className="col-md-6">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  Select Skill (only skill added in course will be displayed here)
                </h5>
                <FormGroup>
                <Select
                  closeMenuOnSelect={false}
                  options={eligibleSkills}
                  placeholder="Select Skill Tags"
                  value={selectedSkills}
                  onChange={(event) => {
                    handleSelectedSkillChange(event)
                  }}
                  isDisabled={!checkVisibility('43', 2) || isJobPublished}
                  isSearchable={true}
                  isMulti
                />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  Graduation Year
                </h5>
                <FormGroup>
                <Select
                  closeMenuOnSelect={false}
                  options={gradYear}
                  placeholder="Select Graduation Year"
                  value={selectedGradYear}
                  onChange={(event) => {
                    handleGradYearChange(event)
                  }}
                  isDisabled={!checkVisibility('43', 2) || isJobPublished}
                  isSearchable={true}
                  isMulti
                />
                </FormGroup>
              </div>

            </li>
            <li className="row">
              <div className="col-md-6">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Gender
                </h5>
                <FormGroup>
                <Select
                closeMenuOnSelect={false}
                  options={genderAllowed}
                  placeholder="Select Gender"
                  value={selectedGender}
                  onChange={(event) => {
                    handleSelectedGenderChange(event)
                  }}
                  isDisabled={!checkVisibility('43', 2) || isJobPublished}
                  isSearchable={true}
                  isMulti
                />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  Work Experience 
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <FormGroup>
                <Select
                  closeMenuOnSelect={false}
                  options={experienceAllowed}
                  placeholder="Select Experience"
                  value={selectedExperience}
                  onChange={(event) => {
                    handleSelectedExperienceChange(event)
                  }}
                  isDisabled={!checkVisibility('43', 2) || isJobPublished}
                  isSearchable={true}
                  isMulti
                />
                </FormGroup>
              </div>
              
            </li>
            <li className="row">
              <div className="col-md-6">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Select Shift Timing
                </h5>
                <FormGroup>
                <Autocomplete
                  disableClearable
                  value={selectedShiftTiming?.name ?? ''}
                  onChange={(event, shift) => {
                    shiftTimingChanged(shift.name, shift._id)
                  }}
                  getOptionLabel={(batch) => {
                    return batch.name || batch
                  }}
                  getOptionSelected={(option, test) => option.name === test}
                  id="batchSelector"
                  options={shiftTiming}
                  disabled={(shiftTiming && shiftTiming.length === 0) || !checkVisibility('43', 2) || isJobPublished ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
                </FormGroup>
              </div>
              <div className="col-md-6">
              {hoverImage ? <div className="showJobPopupSkill2">
                  Nearest Training Center : to identify how many local and other opportunity has been shared
                </div> : ''}
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  Base Location
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  <img src='https://webcdn.imarticus.org/webinar/Vector1.svg' style={{ objectFit: "contain", marginLeft: '5px', marginBottom: '3px'}} onMouseEnter={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)} />
                </h5>
                <FormGroup>
                <Autocomplete
                  disableClearable
              
                  value={selectedlocationDropdown?.name ||""}
                  onChange={(event, location) => {
                    dropdownlocationChanged(location.name, location._id)
                  }}
                  getOptionLabel={(batch) => {
                    return batch.name || batch
                  }}
                  // getOptionSelected={(option, test) => option.name === test}
                  id="batchSelector"
                  options={locationDropdown}
                  disabled={locationDropdown.length === 0 || !checkVisibility('43', 2) || isJobPublished ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
                </FormGroup>
              </div>
            </li>
            <li className="row">
              <div className="col-md-12">           
                <div className='row'>
                  <h5 className="modal-title" htmlFor="thumbImg" style={{ marginLeft: '12px', marginTop: '1rem', marginRight: '10px' }}>
                    Additional Information
                  </h5>
                  
                </div>
                {/* <FroalaEditor addInfoData={addedInfo}/> */}
                <Froala
                  // ref={ref}
                  model={addedInfo}
                  onModelChange={handleModelChange}
                  tag="textarea"
                  config={configs.editorConfig}
                />
              </div>
            </li>

            <div className='addcrit-footer'>

            <Row className="adques-footer">
              <Col md={2} className="jobs-table-header-2">
              <Button
                className="jobs-table-btn-5"
                onClick={ () => {
                  history.push(`/career-module/create-job-description/${jobid}`)
                }}
              >
               {"<"} Previous
              </Button>
              </Col>
              <Col md={5}></Col>
              <Col md={2}>
              {checkVisibility('43', 2) && (
              <Button
                className="labs-table-btn-2"
                onClick={() => savePlacementCriteriaDraft()
                }
                disabled = {isJobPublished ? true : false}
              >
                Save Draft 
              </Button>
              )}
              </Col>
              <Col md={2}>

              {!isJobPublished ? (
              <Button
                className="jobs-table-btn-5"
                onClick={ () => {
                  handleSaveAndRedirect(jobid)
                }}
              >
               Save & Next {">"}
              </Button>
              ) : (
                <Button
                  className="jobs-table-btn-5"
                  onClick={ () => {
                    history.push(`/career-module/add-additional-questions/${jobid}`)
                  }}
                >
                  Next {">"}
                </Button>
              )}
              </Col>
            </Row>
            </div>
            
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
    </div>
  )

}


const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(AddJobCriteria)