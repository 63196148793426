import React, { Component, lazy, Suspense, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { Switch, Route, Redirect } from 'react-router'
// import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Image } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { startNewAccessTokenRequest } from '../../actions/authAction'
import { getUserPermissions } from '../../actions/rptAction'
import globalContext from '../../context/context'
import CourseProvider from '../../context/courseProvider'
import Sidebar from '../../PageComponents/Sidebar/Sidebar'
import { Loader } from 'semantic-ui-react'
import LabsManagement from '../../PageComponents/LabsManagement/LabsManagement'
import CreateLabs from '../../PageComponents/LabsManagement/Components/Labs/CreateLabs'
import SingleLabsPage from '../../PageComponents/LabsManagement/Components/Labs/SingleLabsPage'
import AddImage from '../../PageComponents/LabsManagement/Components/Image/AddImage'
import LabsImages from '../../PageComponents/LabsManagement/Components/Image/LabsImages'
import LabsCost from '../../PageComponents/LabsManagement/Components/Cost/LabsCost'
import AddLabsCost from '../../PageComponents/LabsManagement/Components/Cost/AddLabsCost'
import EditLabsCost from '../../PageComponents/LabsManagement/Components/Cost/EditCost'
import LabsSession from '../../PageComponents/LabsManagement/Components/Session/LabsSession'
// import ManageGradebookAndCertificationNavigation from '../../PageComponents/ManageGradebookAndCertification/ManageGradebookAndCertificationNavigation'
import JobPostings from '../../PageComponents/PlacementPortal/JobPostings'
import ApplicationStatus from '../../PageComponents/PlacementPortal/ApplicationStatus'
import SingleJobApplication from '../../PageComponents/PlacementPortal/SingleJobApplication'
import ViewLearnerProfile from '../../PageComponents/PlacementPortal/ViewLearnerProfile'
import PlacementSettings from '../../PageComponents/PlacementPortal/PlacementSettings'
import PendingJobReviews from '../../PageComponents/PlacementPortal/PendingJobReviews'
import ExceptionApprovalRequests from '../../PageComponents/PlacementPortal/ExceptionApprovalRequests'
import ResumeReviews from '../../PageComponents/PlacementPortal/ResumeReviews'
import ManageLearners from '../../PageComponents/PlacementPortal/ManageLearners'
import PlacementCompany from '../../PageComponents/PlacementPortal/PlacementCompany'
import CreateJobDescription from '../../PageComponents/PlacementPortal/CreateJobDescription'
import AddJobCriteria from '../../PageComponents/PlacementPortal/AddJobCriteria'
import AddAdditionalQuestions from '../../PageComponents/PlacementPortal/AddAdditionalQuestions'
import PreviewJobDescription from '../../PageComponents/PlacementPortal/PreviewJobDescription'
import AddJobRolloutFilters from '../../PageComponents/PlacementPortal/AddJobRolloutFilters'
import DashboardMainBody from '../../Components/ScheduleManagement/Faculty/DashboardMainBody'
import CalendarView from '../../Components/ScheduleManagement/CalendarView/Calendar'
import LearnerAttendanceDetail from '../../Components/LearnerAttendance/LearnerAttendanceDetail'
import TrackAgreements from '../../PageComponents/LearnerMonitoringPanel/DashboardReports/TrackAgreements'

const AdptCourseEditPage = lazy(() =>
  import('../../PageComponents/CourseEditPage/AdptCourseEditPage')
)
const AdptCourseSubjectContentEditPage = lazy(() =>
  import(
    '../../PageComponents/CourseContentEditPage/AdptCourseSubjectContentEditPage'
  )
)
const AdptCourseChapterContentEditPage = lazy(() =>
  import(
    '../../PageComponents/CourseContentEditPage/AdptCourseChapterContentEditPage'
  )
)
const LearnerMonitoringPanel = lazy(() =>
  import('../../PageComponents/LearnerMonitoringPanel/LearnerMonitoringPanel')
)
const NPSReport = lazy(() =>
  import('../../PageComponents/LearnerMonitoringPanel/NPSReport')
)
const LearnerFullReport = lazy(() =>
  import('../../PageComponents/LearnerMonitoringPanel/LearnerFullReport')
)
const FeedMonitor = lazy(() =>
  import('../../PageComponents/FeedMonitoringDashboard/FeedMonitoringDashboard')
)
const GradebookCerti = lazy(() =>
  import('../../PageComponents/GradebookCerti/GradebookCerti')
)
const BatchManagement = lazy(() =>
  import('../../PageComponents/BatchManagement/BatchManagement')
)
const AssignUserPermissions = lazy(() =>
  import('../../PageComponents/AssignUserPermissions/AssignUserPermissions')
)
const AssignmentDeadline = lazy(() =>
  import('../../Components/assignmentDeadline')
)
const QuizAttempt = lazy(() => import('../../Components/quizAttempt'))
const BatchDataExtractionNew = lazy(() =>
  import('../../Components/batchDataExtractionNew')
)
const UserQuizAttempts = lazy(() => import('../../Components/UserQuizAttempts'))
const UserQuizResult = lazy(() => import('../../Components/UserQuizResult'))
const Login = lazy(() => import('../../PageComponents/Login/Login'))
const MainHead = lazy(() => import('../../PageComponents/MainHead/MainHead'))
const Verification = lazy(() =>
  import('../../PageComponents/Verification/Verification')
)
const CourseMainPage = lazy(() =>
  import('../../PageComponents/CourseMainPage/CourseMainPage')
)
const AddCodingAssignment = lazy(() =>
  import('../../PageComponents/AddAssignment/AddCodingAssignment')
)
const AssignmentDetails = lazy(() =>
  import('../../Components/assignmentDetails')
)
const MainPage = lazy(() => import('../../Components/mainpage'))
const AddCourseForm = lazy(() =>
  import('../../PageComponents/AddCourseForm/AddCourseForm')
)
const CourseEditPage = lazy(() =>
  import('../../PageComponents/CourseEditPage/CourseEditPageNew')
)
const SyncSettings = lazy(() =>
  import('../../PageComponents/CourseEditPage/SyncSettings')
)
const AddAdptCourseForm = lazy(() =>
  import('../../PageComponents/AddCourseForm/AddAdptCourseForm')
)
const CourseBatchEditPage = lazy(() =>
  import('../../PageComponents/CourseBatchEditPage/CourseBatchEditPage')
)
const CourseContentEditPage = lazy(() =>
  import('../../PageComponents/CourseContentEditPage/CourseContentEditPage')
)
const AddQuizForm = lazy(() =>
  import('../../PageComponents/AddQuizForm/AddQuizForm')
)
const CertificatePanel = lazy(() =>
  import('../../PageComponents/CertificatePanel/CertificatePanel')
)
const AdptCourseMainPage = lazy(() =>
  import('../../PageComponents/CourseMainPage/AdptCourseMainPage')
)
const CreateSubscriptions = lazy(() =>
  import('../../PageComponents/CreateSubscriptions/CreateSubscriptions')
)
const FeeStructure = lazy(() =>
  import('../../PageComponents/FeeStructure/FeeStructure')
)
const AdmissionModuleSettings = lazy(() =>
  import('../../PageComponents/AdmissionModuleSettings/AdmissionModuleSettings')
)

const ManageNotifications = lazy(() =>
  import('../../Components/NotificationPanel/manageNotifications')
)
const ManageBatchNotifications = lazy(() =>
  import('../../Components/NotificationPanel/manageBatchNotifications')
)
const ManageBroadcasts = lazy(() =>
  import('../../Components/NotificationPanel/manageBroadcasts')
)
const SingleBroadcast = lazy(() =>
  import('../../Components/NotificationPanel/singleBroadcast')
)
const BrandManagement = lazy(() =>
  import('../../PageComponents/BrandManagement/BrandManagement')
)
const UsernameManagement = lazy(() =>
  import('../../Components/Username Management/usernameManagement')
)
const MobileAppManagement = lazy(() =>
  import('../../Components/Mobile App Management/mobileAppManagement')
)
const ChangeStreamManagement = lazy(() =>
  import('../../Components/ChangeStreamManagement/changeStreamManagement')
)
const ManageGradebookAndCertificationNavigation = lazy(() =>
  import(
    '../../PageComponents/ManageGradebookAndCertification/ManageGradebookAndCertificationNavigation'
  )
)
const ScheduleManagement = lazy(() =>
  import('../../Components/ScheduleManagement/ScheduleManagement')
)
const LectureManagement = lazy(() =>
  import('../../Components/ScheduleManagement/Lecture')
)
const FacultyManagement = lazy(() =>
  import('../../Components/ScheduleManagement/Faculty')
)
const ClassroomManagement = lazy(() =>
  import('../../Components/ScheduleManagement/Classroom')
)
const FacultyDashboard = lazy(() =>
  import('../../Components/ScheduleManagement/Faculty/Dashboard')
)
const LearnerAttendance = lazy(() =>
  import('../../Components/LearnerAttendance/LearnerAttendance')
)

const cookies = new Cookies()

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAssignmentPanel: false,
      permissions: {},
      resourceList: [],
      showSidebar: true,
    }

    this.checkVisibility = this.checkVisibility.bind(this)
    this.unlisten = this.unlisten.bind(this)
  }
  componentDidMount() {
    if (cookies.get('isLoggedIn')) {
      let { pathname } = this.props.history.location
      console.log({ pathname })
      getUserPermissions()
        .then((res) => {
          console.log({ res })
          if (res.success) {
            this.setState(
              {
                resourceList: res.data.resources,
                permissions: res.data.permissionMap,
              },
              () => {
                // console.log({
                //   permissions: this.state.permissions,
                //   resourceList: this.state.resourceList,
                // })
                this.props.setPermissions(
                  res.data.permissionMap,
                  res.data.resources
                )
              }
            )
            this.unlisten()
          } else {
            alert(res.message || 'Something went wrong. Try Logging in again.')
            if (pathname != '/') {
              this.props.history.push('/')
            }
          }
        })
        .catch((err) => {
          alert(err?.message || 'Something went wrong. Try Logging in again.')
          if (pathname != '/') {
            this.props.history.push('/')
          }
        })
    }

    if (
      window.location.href.includes('assignment-deadline') ||
      window.location.href.includes('certificate-panel') ||
      window.location.href.includes('learnermonitoringpanel') ||
      window.location.href.includes('feedmonitoringpanel')
    ) {
      document.getElementById('mainSideBar').style.display = 'none'
    } else {
      document.getElementById('mainSideBar').style.display = 'block'
    }
  }
  componentDidUpdate() {
    // console.log('redux:::')
    // console.log({
    //   resources: this.props.resources,
    //   permissions: this.props.permissions,
    // })
    if (this.props.permissions) {
      //console.log(this.props.permissions['14'])
    }
    if (
      window.location.href.includes('assignment-deadline') ||
      window.location.href.includes('certificate-panel') ||
      window.location.href.includes('learnermonitoringpanel') ||
      window.location.href.includes('feedmonitoringpanel')
      // || window.location.href.includes('placement-module')
    ) {
      document.getElementById('mainSideBar').style.display = 'none'
    } else {
      document.getElementById('mainSideBar').style.display = 'block'
    }
    startNewAccessTokenRequest()
  }
  checkVisibility(resource) {
    if (!this.props.permissions) {
      return true
    } else {
      if (!this.props.permissions[resource]) {
        return false
      } else if (this.props.permissions[resource].indexOf(1) != -1) {
        console.log(`Permitted ${resource}`)
        return true
      } else {
        return false
      }
    }
  }
  unlisten() {
    this.props.history.listen((location, action) => {
      console.log({ location: location })
      let { pathname } = location
      //console.log('on route change')
      if (pathname != '/verify') {
        getUserPermissions()
          .then((res) => {
            console.log({ res })
            if (res.success) {
              this.setState(
                {
                  resourceList: res.data.resources,
                  permissions: res.data.permissionMap,
                },
                () => {
                  // console.log({
                  //   permissions: this.state.permissions,
                  //   resourceList: this.state.resourceList,
                  // })
                  this.props.setPermissions(
                    res.data.permissionMap,
                    res.data.resources
                  )
                }
              )
              // thissetPermissionssetPermissions.unlisten()
            } else {
              alert(
                res.message || 'Something went wrong. Try Logging in again.'
              )
              if (pathname != '/') {
                this.props.history.push('/')
              }
            }
          })
          .catch((err) => {
            alert(err?.message || 'Something went wrong. Try Logging in again.')
            if (pathname != '/') {
              this.props.history.push('/')
            }
          })
      }
    })
  }

  render() {
    var at = cookies.get('isLoggedIn')
    console.log({ at })

    var loggedIn = at !== undefined
    return (
      <CourseProvider>
        <div id="mainSideBar">
          {!window.location.pathname.startsWith('/career-module') && (
            <Sidebar />
          )}
        </div>
        <Suspense
          fallback={
            <div className="Loader">
              <Loader active inline />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              render={() => (loggedIn ? <Redirect to="/head" /> : <Login />)}
            />
            <Route
              path="/verify"
              render={() =>
                loggedIn ? <Redirect to="/head" /> : <Verification />
              }
            />
            <Route
              path="/head"
              render={(props) =>
                loggedIn ? (
                  <MainHead {...props} isAuthed={true} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            {/* <Route path="/assignmentpanel" exact component={ () => (loggedIn && this.checkVisibility('3') ? <MainPage /> : (<Redirect to="/" />))} /> */}
            <Route
              path="/assignmentpanel"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('3') ? (
                  <MainPage {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/codingAssignment/:cid"
              render={() =>
                loggedIn ? <AddCodingAssignment /> : <Redirect to="/" />
              }
            />
            <Route
              path="/details/:ucs/:crs_pg_id/:loc_id/:course_id/:asgnmnt_id/:aid/:status"
              exact
              component={AssignmentDetails}
            />
            <Route
              path="/main"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <CourseMainPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/add"
              render={() =>
                loggedIn ? <AddCourseForm /> : <Redirect to="/" />
              }
            />
            <Route
              path="/editCourse"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <CourseEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/syncSettings"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <SyncSettings />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/gradebookandcertification"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <ManageGradebookAndCertificationNavigation />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/editBatch"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <CourseBatchEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/quiz"
              render={() => (loggedIn ? <AddQuizForm /> : <Redirect to="/" />)}
            />
            <Route
              path="/editChapterContent/"
              render={() =>
                loggedIn &&
                this.checkVisibility('1') &&
                this.checkVisibility('17') ? (
                  <CourseContentEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/certificate-panel"
              render={() =>
                loggedIn && this.checkVisibility('4') ? (
                  <CertificatePanel />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/adptCoursePanel"
              render={() =>
                loggedIn && this.checkVisibility('2') ? (
                  <AdptCourseMainPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/addAdptCourse"
              render={() =>
                loggedIn && this.checkVisibility('2') ? (
                  <AddAdptCourseForm />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/editAdptCourse"
              render={() =>
                loggedIn && this.checkVisibility('2') ? (
                  <AdptCourseEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/editAdptSubjectContent"
              render={() =>
                loggedIn && this.checkVisibility('2') ? (
                  <AdptCourseSubjectContentEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/editAdptChapterContent"
              render={() =>
                loggedIn && this.checkVisibility('2') ? (
                  <AdptCourseChapterContentEditPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            {/* <Route
              path="/learnermonitoringpanel/npsreport"
              render={() => (loggedIn ? <NPSReport /> : <Redirect to="/" />)}
            />
            <Route
              path="/learnermonitoringpanel/learnerreport"
              render={() =>
                loggedIn && this.checkVisibility('6') ? (
                  <LearnerFullReport />
                ) : (
                  <Redirect to="/" />
                )
              }
            /> */}
            <Route
              path="/learnermonitoringpanel"
              render={() =>
                loggedIn &&
                (this.checkVisibility('6') || this.checkVisibility('54')) ? (
                  <LearnerMonitoringPanel />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              path="/feedmonitoringpanel"
              render={() =>
                loggedIn && this.checkVisibility('10') ? (
                  <FeedMonitor />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/assignment-deadline"
              exact
              render={() =>
                loggedIn && this.checkVisibility('11') ? (
                  <AssignmentDeadline />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/quiz-attempt"
              exact
              render={() =>
                loggedIn && this.checkVisibility('27') ? (
                  <QuizAttempt />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/batch-data"
              exact
              render={() =>
                loggedIn && this.checkVisibility('12') ? (
                  <BatchDataExtractionNew />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/grade-certi"
              exact
              render={() =>
                loggedIn && this.checkVisibility('13') ? (
                  <GradebookCerti />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/batch-manage"
              exact
              render={() =>
                loggedIn && this.checkVisibility('14') ? (
                  <BatchManagement />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/user-permissions"
              exact
              render={() =>
                loggedIn && this.checkVisibility('15') ? (
                  <AssignUserPermissions />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-notifications"
              exact
              render={() =>
                loggedIn && this.checkVisibility('28') ? (
                  <ManageNotifications />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-batch-notifications"
              exact
              render={() =>
                loggedIn && this.checkVisibility('28') ? (
                  <ManageBatchNotifications />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-broadcasts"
              exact
              render={() =>
                loggedIn && this.checkVisibility('28') ? (
                  <ManageBroadcasts />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/batch-data/:userid/:testName/:testType/:testid/:crs_quiz_id/:crs_pg_id/:crs_id"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('12') ? (
                  <UserQuizAttempts {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/quizanswers/:testId/result/:usertestId/:crs_pg_id/:crs_id"
              render={(props) =>
                loggedIn && this.checkVisibility('12') ? (
                  <UserQuizResult {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-subscriptions"
              exact
              render={() =>
                loggedIn && this.checkVisibility('18') ? (
                  <CreateSubscriptions />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/fee-structure"
              exact
              render={() =>
                loggedIn && this.checkVisibility('19') ? (
                  <FeeStructure />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/admission-module"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('20') ? (
                  <AdmissionModuleSettings {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/admission-module/:setting"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('20') ? (
                  <AdmissionModuleSettings {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-broadcasts/viewBroadcast/:broadcastId"
              exact
              render={() =>
                loggedIn && this.checkVisibility('28') ? (
                  <SingleBroadcast ifAdd={true} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/manage-broadcasts/addBroadcast"
              exact
              render={() =>
                loggedIn && this.checkVisibility('28') ? (
                  <SingleBroadcast ifAdd={false} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/brand-management"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('26') ? (
                  <BrandManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/username-management"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('29') ? (
                  <UsernameManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <LabsManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/createLabs"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <CreateLabs {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/addImage"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <AddImage {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/images"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <LabsImages {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/cost"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <LabsCost {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/addCost"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <AddLabsCost {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/editLabsCost/:id"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <EditLabsCost {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/labs-management/session"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <LabsSession {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              path="/labs-management/:id"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('31') ? (
                  <SingleLabsPage {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              path="/mobile-app-management"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('32') ? (
                  <MobileAppManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              path="/audit-logs"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('38') ? (
                  <ChangeStreamManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            {/* <Route
                path="/schedule-management/faculty"
                exact
                render={(props) =>
                  loggedIn && this.checkVisibility('38') ? (
                    <ScheduleManagement {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path="/schedule-management/classroom"
                exact
                render={(props) =>
                  loggedIn && this.checkVisibility('38') ? (
                    <ScheduleManagement {...props} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              /> */}

            <Route
              path={[
                '/schedule-management',
                '/schedule-management/classroom',
                '/schedule-management/faculty',
              ]}
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('49') ? (
                  <ScheduleManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/schedule-management/publishedLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('49') ? (
                  <ScheduleManagement page={'publishedLectures'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/schedule-management/completedLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('49') ? (
                  <ScheduleManagement page={'completedLectures'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/schedule-management/cancelledLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('49') ? (
                  <ScheduleManagement page={'cancelledLectures'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/schedule-management"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('49') ? (
                  <ScheduleManagement {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            <Route
              path="/career-services"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('41') ? (
                  <JobPostings {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/application-status"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('42') ? (
                  <ApplicationStatus {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/single-jobapplication-status/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('42') ? (
                  <SingleJobApplication {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/view-learner-profile/:crs_pg_id/:student_aid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('42') ? (
                  <ViewLearnerProfile {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/pending-job-reviews"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('1') ? (
                  <PendingJobReviews {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/exception-approval-requests"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('1') ? (
                  <ExceptionApprovalRequests {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/resume-reviews"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('44') ? (
                  <ResumeReviews {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/manage-learners"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('47') ? (
                  <ManageLearners {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/placement-settings"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('46') ? (
                  <PlacementSettings {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-services/placement-companies"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('45') ? (
                  <PlacementCompany {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-module/create-job-description/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('43') ? (
                  <CreateJobDescription {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-module/add-job-criteria/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('43') ? (
                  <AddJobCriteria {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-module/add-additional-questions/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('43') ? (
                  <AddAdditionalQuestions {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-module/preview-job-description/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('43') ? (
                  <PreviewJobDescription {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/career-module/job-rollout-filters/:jobid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('43') ? (
                  <AddJobRolloutFilters {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/faculty-dashboard"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <FacultyDashboard page="upcoming" {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/faculty-dashboard/upcomingLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <FacultyDashboard page={'upcoming'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/faculty-dashboard/completedLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <FacultyDashboard page={'completed'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/faculty-dashboard/cancelledLectures"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <FacultyDashboard page={'cancelled'} {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/learners-attendance"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <LearnerAttendance page="upcoming" {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/learners-attendance/:aid/:cid"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('50') ? (
                  <LearnerAttendanceDetail {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/calendar/:typeof/:id"
              render={(props) =>
                loggedIn ? (
                  <CalendarView {...props} isAuthed={true} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/trackAgreements/"
              exact
              render={(props) =>
                loggedIn && this.checkVisibility('53') ? (
                  <TrackAgreements {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route path="*" exact render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </CourseProvider>
    )
  }
}

class NoMatch extends Component {
  render() {
    return <Image src="./error-page.jpg" centered />
  }
}
const mapStateToProps = (state) => {
  //console.log({ reduxState: state })
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setPermissions: (permissions, resources) => {
    dispatch({
      type: 'PANEL_PERMISSIONS',
      payload: { permissions, resources },
    })
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)
