import React, { useEffect, useState, useRef, createRef } from 'react'
import styles from './index_module.scss'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import Sidebar2 from './Components/Sidebar2'
import { connect } from 'react-redux'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  FormText
} from 'reactstrap'
import { history } from '../..'
import { COUNTRY_CODES } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";


import Froala from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import {
  savePlacementJobDescription,
  uploadCompanyLogo
} from '../../actions/jobAction.js'
const configs = require('../../config/configs.js')

const CreateJobDescription = (props) => {
  const [requests, setRequests] = useState([])
  const [jobLocation, setJobLocation] = useState('')
  const [location, setLocation] = useState([])
  const [locations, setLocations] = useState([])
  const [validFrom, setValidFrom] = useState('')
  const [key, setKey] = useState(Date.now())
  const [companyLogo, setCompanyLogo] = useState()
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyShortCode, setCompanyShortCode] = useState('')
  const [ctcAmount, setCTCAmount] = useState(0)
  const [ctcRangeMin, setCTCRangeMin] = useState(0)
  const [ctcRangeMax, setCTCRangeMax] = useState(0)
  const [jobType, setJobType] = useState([])
  const [selectedJobType, setSelectedJobType] = useState({})
  const [jobTitle, setJobTitle] = useState('')
  const [selectedDomain, setSelectedDomain] = useState({})
  const [jobDomain, setJobDomain] = useState([
    { value: 0, label: 'Technology' },
    { value: 1, label: 'Finance' },
    { value: 2, label: 'Data Analytics' },
    { value: 3, label: 'Banking' },
    { value: 4, label: 'Others' }
  ])
  const [selectedInterviewMode, setSelectedInterviewMode] = useState({})
  const [interviewMode, setInterviewMode] = useState({})
  const [totalPositions, setTotalPositions] = useState(0)
  const [locationName, setLocationName] = useState('ALL')
  const [locationId, setLocationId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [bondDuration, setBondDuration] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [slug, setSlug] = useState(null)
  const [block, setBlock] = useState(undefined)
  const [stages, setStages] = useState(null)
  const [formData, setFormData] = useState({})
  const [fileErrors, setFileErrors] = useState([])
  const [isValidForm, setIsValidForm] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [bondIncluded, setBondIncluded] = useState("false")
  const [ctcType, setCTCType] = useState('amount')
  const [showInternCTC, setShowInternCTC] = useState(false);
  const [internctcType, setInternCTCType] = useState('amount')
  const [internctcAmount, setInternCTCAmount] = useState(0)
  const [internctcRangeMin, setInternCTCRangeMin] = useState(0)
  const [internctcRangeMax, setInternCTCRangeMax] = useState(0)
  const [numRounds, setNumRounds] = useState(0)
  const [roundTypes, setRoundTypes] = useState([])
  const [selectedRoundTypes, setSelectedRoundTypes] = useState([])
  const [selectedMode, setSelectedMode] = useState({})
  const [mode, setMode] = useState([])
  const [jobDescStatus, setJobDescStatus] = useState([])
  const [jobStatus, setJobStatus] = useState('All')
  const [errorMessage, setErrorMessage] = useState('');
  const [jobStatuses, setJobStatuses] = useState(['All', 'PENDING',
    'NOT_PUBLISHED',
    'LIVE',
    'HIRING_COMPLETE',
    'CANCELLED',])
  const [formErrors, setFormErrors] = useState({
    errors: [],
    uiSchema: {},
    formData: {},
  })
  const [createdDescId, setCreatedDescId] = useState('')
  const [jobData, setJobData] = useState([])
  const [dragging, setDragging] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [roleData, setRoleData] = useState("");
  const [bondData, setBondData] = useState("")
  const [jobPublished, setJobPublished] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')
  const [createdJDData, setCreatedJDData] = useState({})
  const FormRef = createRef()
  const submitButtonRef = useRef(null)
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]

  let fileInput = useRef(null);

  const JobDescriptionStatuses = {
    UNDER_REVIEW: 1,
    APPROVED: 2,
    REJECTED: 3,
    FLAGGED: 4,
  }

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT
            }/cfpanelPlacement/get_jobdescription_data?jobid=${jobid
            }`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            console.log({ jobdesc: res.data })
            let data = res.data.data
            // let tmp_data =
            //   res.data.data && res.data.data.allLocations
            //     ? res.data.data.allLocations
            //     : []
            let result = []
            setCompanyName(data.job.company_name)
            setCompanyShortCode(data.company_shortcode)
            setTotalPositions(data.job.num_openings)
            if (data.job.job_status && data.job.job_status > 2) {
              setJobPublished(true)
            }
            setJobType(data.jobDescFields.jobTypeList)
            // setJobDomain(data.jobDescFields.domainList)
            setInterviewMode(data.jobDescFields.interviewModeList)
            setMode(data.jobDescFields.modeList)
            setRoundTypes(data.jobDescFields.roundTypesList)
            setJobDescStatus(data.jobDescFields.jobDescStatusList)
            // setLocation(data.jobDescFields.jobLocationList)
            if (data.alreadyCreatedJobDescription.length > 0) {
              console.log("in here")
              const createdData = data.alreadyCreatedJobDescription[0]
              const jdField = data.jobDescFields
              const selectedDomainObjects = [];
              const selectedModeObjects = [];
              const selectedJobTypeObjects = [];
              const selectedInterviewModeObjects = [];
              console.log({ createdData: createdData })


              createdData.selected_domain.forEach(index => {
                console.log({ index: index })
                const gender = jobDomain.find(gender => gender.value == index);
                console.log({ selected_domain: gender })
                if (gender) {
                  selectedDomainObjects.push(gender);
                }
              });

              createdData.selected_mode.forEach(index => {
                const gender = jdField.modeList.find(gender => gender.value == index);
                if (gender) {
                  selectedModeObjects.push(gender);
                }
              });
              createdData.selected_job_type.forEach(index => {
                const gender = jdField.jobTypeList.find(gender => gender.value == index);
                if (gender) {
                  selectedJobTypeObjects.push(gender);
                }
              });
              // createdData.job_location.forEach(index => {
              //   const gender = jdField.jobLocationList.find(gender => gender.value == index);
              //   if (gender) {
              //     selectedJobLocationObjects.push(gender);
              //   }
              // });
              createdData.interview_mode.forEach(index => {
                const gender = jdField.interviewModeList.find(gender => gender.value == index);
                if (gender) {
                  selectedInterviewModeObjects.push(gender);
                }
              });
              if (createdData.ctc_type == 1) {
                setCTCType('range')
              } else {
                setCTCType('amount')
              }
              if (createdData && createdData.intern_ctc_type && createdData.intern_ctc_type == 1) {
                setInternCTCType('range')
              } else {
                setInternCTCType('amount')
              }
              console.log({ selectedDomainObjects })
              setCompanyLogo(createdData.company_logo)
              setCreatedDescId(createdData._id)
              setSelectedDomain(selectedDomainObjects)
              setSelectedMode(selectedModeObjects)
              setSelectedInterviewMode(selectedInterviewModeObjects)
              setSelectedJobType(selectedJobTypeObjects)
              setJobLocation(createdData.job_loc_info)
              setCompanyWebsite(createdData.company_website)
              setBondIncluded(createdData.bond_included)
              setRoleData(createdData.roles_info)
              setBondData(createdData.bond_additional_info)
              setNumRounds(createdData.num_rounds)
              setSelectedRoundTypes(createdData.rounds_order)
              setCTCAmount(createdData.ctc_amount)
              setCTCRangeMin(createdData.ctc_range_min)
              setCTCRangeMax(createdData.ctc_range_max)
              setBondDuration(createdData.bond_duration)
              setJobTitle(createdData.job_title)
              setCreatedJDData(createdData)

              if (createdData && createdData.intern_ctc_enabled) {
                setShowInternCTC(createdData.intern_ctc_enabled)
                setInternCTCAmount(createdData.intern_ctc_amount)
                setInternCTCRangeMin(createdData.intern_ctc_range_min)
                setInternCTCRangeMin(createdData.intern_ctc_range_max)
              }
            }
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()


  }, [refresh, page, rowsPerPage])

  const handleBondDataChange = (model) => {
    setBondData(model);
  };
  const handleModelChange = (model) => {
    setRoleData(model);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleCheckboxChange = (event) => {
    setShowInternCTC(event.target.checked);
  };

  const checkVisibility = (resource, resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
    // Handle file upload logic here
    console.log('Uploaded file:', file);
  };

  const handleCTCAmountChange = (event) => {
    const value = event.target.value;
    const max = 5000000;

    if (value > max) {
      setErrorMessage('CTC Amount can not exceed the max permitted value');
    } else if (value !== '' && !/^\d+$/.test(value)) {
      setErrorMessage('CTC amount must be a valid number');
    } else {
      setErrorMessage('');
      setCTCAmount(value);
    }


  };

  const handleInternCTCAmountChange = (event) => {
    const value = event.target.value;
    const max = 5000000;

    if (value > max) {
      setErrorMessage('Internship CTC Amount can not exceed the max permitted value');
    } else if (value !== '' && !/^\d+$/.test(value)) {
      setErrorMessage('Internship CTC amount must be a valid number');
    } else {
      setErrorMessage('');
      setInternCTCAmount(value);
    }


  };

  const handleBondIncludedChange = (event) => {
    setBondIncluded(event.target.value)
  }

  const handleCTCTypeChange = (event) => {
    if (createdJDData && Object.keys('createdJDData').length > 0) {
      console.log("created JD Exists")
      // setCTCAmount(createdJDData.ctc_amount)
      // setCTCRangeMin(createdJDData.ctc_range_min)
      // setCTCRangeMax(createdJDData.ctc_range_max)
      setCTCAmount(0);
      setCTCRangeMin(0);
      setCTCRangeMax(0);
      document.getElementById("ctcAmount").value = 0;
      document.getElementById("ctcMinAmount").value = 0;
      document.getElementById("ctcMaxAmount").value = 0;
    } else {
      console.log("created JD doesnt Exists")
      setCTCAmount(0);
      setCTCRangeMin(0);
      setCTCRangeMax(0);
      document.getElementById("ctcAmount").value = 0;
      document.getElementById("ctcMinAmount").value = 0;
      document.getElementById("ctcMaxAmount").value = 0;
    }

    setCTCType(event.target.value)
  }

  const handleInternCTCTypeChange = (event) => {
    if (createdJDData && Object.keys('createdJDData').length > 0) {
      console.log("created JD Exists")
      // setCTCAmount(createdJDData.ctc_amount)
      // setCTCRangeMin(createdJDData.ctc_range_min)
      // setCTCRangeMax(createdJDData.ctc_range_max)
      setInternCTCAmount(0);
      setInternCTCRangeMin(0);
      setInternCTCRangeMax(0);
      document.getElementById("internctcAmount").value = 0;
      document.getElementById("internctcMinAmount").value = 0;
      document.getElementById("internctcMaxAmount").value = 0;
    } else {
      console.log("created JD doesnt Exists")
      setInternCTCAmount(0);
      setInternCTCRangeMin(0);
      setInternCTCRangeMax(0);
      document.getElementById("internctcAmount").value = 0;
      document.getElementById("internctcMinAmount").value = 0;
      document.getElementById("internctcMaxAmount").value = 0;
    }

    setInternCTCType(event.target.value)
  }

  let chooseCompanyLogo = (ev) => {
    const file = ev.target.files[0]
    let res = validCompanyLogo(file)

    if (!res.valid) {
      window.alert(res.errorString)
    } else {
      setCompanyLogo(file)
      uploadCompanyLogo(file, companyShortCode)
        .then(({ success, data, message }) => {
          setIsLoading(false)
          console.log({ success: success })
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert('Something went wrong. Please try again.');
            }
          } else {
            setCompanyLogo(data.img_url);
            setOpenSuccessModal(true)
          }
        })
        .catch(error => {
          console.error('An error occurred:', error);
          // Handle any errors that occurred during the async operation
        });

    }
  }

  let validCompanyLogo = (file) => {
    const supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png']

    let errorList = []

    if (file.size > 50 * 1024) {
      errorList.push('File Size exceeds limit')
    }

    if (supportedFileTypes.indexOf(file.type) == -1) {
      errorList.push('File type is unsupported')
    }

    let errorString = errorList.join(' and ')
    if (errorList.length > 0) {
      return {
        valid: false,
        errorString,
      }
    } else {
      return {
        valid: true,
      }
    }
  }

  // const handleModalClose = () => {
  //   setTotalPositions(0)
  // }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
  }
  const handleSelectedDomainChange = (e) => {

    const selectedDomain = e
    console.log({ selectedDomain: selectedDomain })
    setSelectedDomain(selectedDomain);
  }
  const handleSelectedJobTypeChange = (e) => {
    console.log({ e: e })
    const selectedJobType = e
    console.log({ selectedJobType: selectedJobType })
    setSelectedJobType(selectedJobType);
  }

  const handleSelectedModeChange = (e) => {
    console.log({ e: e })
    const selectedMode = e
    console.log({ selectedMode: selectedMode })
    setSelectedMode(selectedMode);
  }

  const handleSelectedInterviewModeChange = (e) => {
    console.log({ e: e })
    const selectedInterviewMode = e
    console.log({ selectedInterviewMode: selectedInterviewMode })
    setSelectedInterviewMode(selectedInterviewMode);
  }

  const handleSaveAndRedirect = async (jobid) => {
    try {
      await savePlacementJobDescriptionRedirect();

    } catch (error) {
      console.error('Error saving draft:', error);
    }
  };

  const handleSelectedRoundTypeChange = (index, selectedOption) => {
    console.log({ selectedOption: selectedOption })
    console.log({ index: index })

    setSelectedRoundTypes((prevSelectedRoundTypes) => {
      const newSelectedRoundTypes = [...prevSelectedRoundTypes];
      newSelectedRoundTypes[index] = selectedOption;
      return newSelectedRoundTypes;
    })
    console.log({ selectedRoundTypes: selectedRoundTypes })
  }

  const savePlacementJobDescriptionDraft = async () => {
    console.log({ selectedDomain })

    if (
      !companyWebsite
    ) {
      window.alert("Please fill the Company Website");
      return;
    }

    if (
      !selectedDomain
    ) {
      window.alert("Please fill the Job Domain");
      return;
    }

    if (
      !jobTitle
    ) {
      window.alert("Please fill the Job Title");
      return;
    }

    if (
      !selectedJobType
    ) {
      window.alert("Please fill the Job Type");
      return;
    }

    if (
      !selectedMode
    ) {
      window.alert("Please fill the Job Mode");
      return;
    }

    if (
      jobLocation.trim() === ''
    ) {
      window.alert("Please fill the Job Location");
      return;
    }

    if (
      !selectedInterviewMode
    ) {
      window.alert("Please fill the Interview Mode");
      return;
    }


    if (
      !ctcType ||
      !numRounds
    ) {
      window.alert("Please fill all the required fields.");
      return;
    }

    setIsLoading(true)
    let { success, message } = await savePlacementJobDescription({
      jobid,
      descid: createdDescId,
      company_website: companyWebsite,
      selected_domain: selectedDomain,
      job_title: jobTitle,
      selected_job_type: selectedJobType,
      selected_mode: selectedMode,
      num_openings: totalPositions,
      ctc_type: ctcType,
      ctc_amount: ctcAmount,
      ctc_range_min: ctcRangeMin,
      ctc_range_max: ctcRangeMax,
      intern_ctc_enabled: showInternCTC,
      intern_ctc_type: internctcType,
      intern_ctc_amount: internctcAmount,
      intern_ctc_range_min: internctcRangeMin,
      intern_ctc_range_max: internctcRangeMax,
      job_location: jobLocation,
      bond_included: bondIncluded,
      bond_duration: bondDuration,
      bond_additional_info: bondData,
      interview_mode: selectedInterviewMode,
      num_rounds: numRounds,
      rounds_order: selectedRoundTypes,
      job_desc_status: jobDescStatus,
      roles_info: roleData,
      company_logo: companyLogo
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.alert('Success')
    }
    // load()
    setIsLoading(false)
  }

  const savePlacementJobDescriptionRedirect = async () => {

    if (
      !companyWebsite
    ) {
      window.alert("Please fill the Company Website");
      return;
    }

    if (
      !selectedDomain
    ) {
      window.alert("Please fill the Job Domain");
      return;
    }

    if (
      !jobTitle
    ) {
      window.alert("Please fill the Job Title");
      return;
    }

    if (
      !selectedJobType
    ) {
      window.alert("Please fill the Job Type");
      return;
    }

    if (
      !selectedMode
    ) {
      window.alert("Please fill the Job Mode");
      return;
    }

    if (
      jobLocation.trim() === ''
    ) {
      window.alert("Please fill the Job Location");
      return;
    }

    if (
      !selectedInterviewMode
    ) {
      window.alert("Please fill the Interview Mode");
      return;
    }


    if (
      !ctcType ||
      !numRounds
    ) {
      window.alert("Please fill all the required fields.");
      return;
    }

    setIsLoading(true)
    let { success, message } = await savePlacementJobDescription({
      jobid,
      descid: createdDescId,
      company_website: companyWebsite,
      selected_domain: selectedDomain,
      job_title: jobTitle,
      selected_job_type: selectedJobType,
      selected_mode: selectedMode,
      num_openings: totalPositions,
      ctc_type: ctcType,
      ctc_amount: ctcAmount,
      ctc_range_min: ctcRangeMin,
      ctc_range_max: ctcRangeMax,
      intern_ctc_enabled: showInternCTC,
      intern_ctc_type: internctcType,
      intern_ctc_amount: internctcAmount,
      intern_ctc_range_min: internctcRangeMin,
      intern_ctc_range_max: internctcRangeMax,
      job_location: jobLocation,
      bond_included: bondIncluded,
      bond_duration: bondDuration,
      bond_additional_info: bondData,
      interview_mode: selectedInterviewMode,
      num_rounds: numRounds,
      rounds_order: selectedRoundTypes,
      job_desc_status: jobDescStatus,
      roles_info: roleData,
      company_logo: companyLogo
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      // window.alert('Success')
      history.push(`/career-module/add-job-criteria/${jobid}`);
    }
    // load()
    setIsLoading(false)
  }


  const FileUpload = (props) => {
    const { value, onChange, uiSchema } = props
    console.log({ accept: uiSchema['ui:options'].accept })
    const allowedInput = (uiSchema['ui:options'].accept || ['*']).toString()
    const [file, setFile] = useState()
    const handleChange = (e) => {
      setFile(e.target.files[0])
      onChange(e.target.files[0])
    }
    console.log({ value })

    return (
      <div>
        <p>
          <input
            type={'file'}
            accept={allowedInput}
            onChange={handleChange}
          ></input>
        </p>
        {value && value.name && <div className="fileName">{value.name}</div>}
        {value && !value.name && (
          <a href={value} className="fileName">
            {new URL(value).pathname.replace('/', '')}
          </a>
        )}
        {value && (
          <button
            className="fileRemove"
            onClick={() => {
              onChange(undefined)
            }}
          >
            Remove
          </button>
        )}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
      <div className="jobs-main">
        <TopBar2
          heading={'Create a Job'}
        />
        <div>
          <Sidebar2 active={1} jobid={jobid} />
        </div>
        <div>
          <div className="jobs-header">
            <Row style={{ height: '30px' }}>
              <MiniHeader breadCrumbs={[
                { name: "Career Services", link: "/career-services" },
                { name: "Job Postings", link: "/career-services" },
              ]}
                active={1}
              />
            </Row>
          </div>
          <div className="jobs-content">
            <div className="adques-all">
              <div className="adques-header">
                <Col md={9} className="jobs-table-header-2">
                  <p style={{ marginBottom: '10px' }}>Job Description</p>
                </Col>
              </div>
              <div style={{ marginTop: '2rem' }}>
              </div>
              <div className='addcrit-content'>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Company Name
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={companyName}
                        onChange={(event, oppID) => {
                          setCompanyName(event.target.value)
                        }}
                        style={{ width: '98%' }}
                        disabled={companyName !== "" || !checkVisibility('43', 2) || jobPublished}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Company Website
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={companyWebsite}
                        onChange={(event, oppID) => {
                          setCompanyWebsite(event.target.value)
                        }}
                        disabled={!checkVisibility('43', 2) || jobPublished ? true : false}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-12">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Upload Company Logo
                    </h5>
                    {companyLogo && (
                      <a className="file-name" href={companyLogo} target="_blank" rel="noopener noreferrer">
                        Uploaded Logo
                      </a>
                    )}
                    <FormGroup>
                      <div className={`upload-div drop-zone ${dragging ? 'dragging' : ''}`} onClick={() => fileInput.click()}
                        style={{ cursor: "pointer" }}>
                        <h5 style={{ cursor: "pointer !important" }} className='upload-text'>Upload files here</h5>

                        <input
                          type="file"
                          name="file"
                          id="fileInput"
                          ref={(input) => { fileInput = input; }}
                          style={{ display: 'none' }}
                          onChange={(event) => {
                            // Handle file upload logic here
                            const file = event.target.files[0];
                            console.log('Uploaded file:', file);
                            chooseCompanyLogo(event)
                          }}
                          disabled={!checkVisibility('43', 2) || jobPublished}
                        />
                      </div>
                      <p>Upload image in a format of PNG, JPG or JPEG. Max Size 50KB</p>
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Domain
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Select
                        options={jobDomain}
                        placeholder="Select Domain"
                        value={selectedDomain}
                        onChange={(event) => {
                          handleSelectedDomainChange(event)
                        }}
                        isDisabled={!checkVisibility('43', 2) || jobPublished}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Enter Job Title
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={jobTitle}
                        onChange={(event, oppID) => {
                          setJobTitle(event.target.value)
                        }}
                        disabled={!checkVisibility('43', 2) || jobPublished}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Job Type
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Select
                        options={jobType}
                        placeholder="Select Job Type"
                        value={selectedJobType}
                        onChange={(event) => {
                          handleSelectedJobTypeChange(event)
                        }}
                        isDisabled={!checkVisibility('43', 2) || jobPublished}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Mode
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Select
                        options={mode}
                        placeholder="Select Mode"
                        value={selectedMode}
                        onChange={(event) => {
                          handleSelectedModeChange(event)
                        }}
                        isDisabled={!checkVisibility('43', 2) || jobPublished}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Number of Openings
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="number"
                        name="text"
                        id="courseShortCode"
                        value={totalPositions}
                        max="1000"
                        min="0"
                        onChange={(event, oppID) => {
                          setTotalPositions(event.target.value)
                        }}
                        style={{ width: '98%' }}
                        disabled={companyName !== "" || !checkVisibility('43', 2) || jobPublished}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-12">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      Select CTC
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <div className='row' >
                      <FormControl component="fieldset" className='ctc-radio' style={{ width: '100%', flexDirection: 'row' }}>
                        <RadioGroup
                          aria-label="ctcType"
                          value={ctcType}
                          onChange={handleCTCTypeChange}
                          disabled={!checkVisibility('43', 2) || jobPublished}
                          style={{ flexDirection: 'row', display: 'contents' }}
                        >
                          <FormControlLabel className="col-md-6 radiotext" value="amount" control={<Radio />} label="Enter Amount (in Rupees)" />
                          <FormControlLabel className="col-md-6 radiotext" value="range" control={<Radio />} label="Select Range (Only Integers are allowed)" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <FormGroup>
                      <Input
                        type="number"
                        name="text"
                        id="ctcAmount"
                        placeholder="Enter Amount"
                        value={ctcAmount}
                        onChange={handleCTCAmountChange}
                        max="5000000"
                        min="0"
                        style={{ width: '98%' }}
                        disabled={ctcType === "range" || !checkVisibility('43', 2) || jobPublished}
                      />
                      {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
                    </FormGroup>
                  </div>
                  <div className="col-md-6 row" style={{ marginTop: '0rem' }} >
                    <div className="col-md-5">
                      <FormGroup>
                        <Input
                          type="number"
                          name="text"
                          id="ctcMinAmount"
                          placeholder="Min Amount"
                          value={ctcRangeMin}
                          onChange={(event, oppID) => {
                            if (event.target.value > 5000000) {
                              window.alert('Min CTC amount should not exceed 5000000')
                            } else {
                              setCTCRangeMin(event.target.value)
                            }
                          }}
                          max="5000000"
                          min="0"
                          style={{ width: '98%' }}
                          disabled={ctcType === "amount" || !checkVisibility('43', 2) || jobPublished}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-5">
                      <FormGroup>
                        <Input
                          type="number"
                          name="text"
                          id="ctcMaxAmount"
                          placeholder="Max Amount"
                          value={ctcRangeMax}
                          onChange={(event, oppID) => {
                            if (event.target.value > 5000000) {
                              window.alert('Max CTC amount should not exceed 5000000')
                            } else {
                              setCTCRangeMax(event.target.value)
                            }
                          }}
                          max="5000000"
                          min={ctcRangeMin}
                          style={{ width: '98%' }}
                          disabled={ctcType === "amount" || !checkVisibility('43', 2) || jobPublished}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showInternCTC}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Include Internship CTC"
                    />
                  </div>
                </li>
                {showInternCTC && (
                  <>
                    <li className='row'>
                      <div className="col-md-12">
                        <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select Internship CTC
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <div className='row' >
                          <FormControl component="fieldset" className='ctc-radio' style={{ width: '100%', flexDirection: 'row' }}>
                            <RadioGroup
                              aria-label="internctcType"
                              value={internctcType}
                              onChange={handleInternCTCTypeChange}
                              disabled={!checkVisibility('43', 2) || jobPublished}
                              style={{ flexDirection: 'row', display: 'contents' }}
                            >
                              <FormControlLabel className="col-md-6 radiotext" value="amount" control={<Radio />} label="Enter Amount (in Rupees)" />
                              <FormControlLabel className="col-md-6 radiotext" value="range" control={<Radio />} label="Select Range (Only Integers are allowed)" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </li>
                    <li className='row'>
                      <div className="col-md-6">
                        <FormGroup>
                          <Input
                            type="number"
                            name="text"
                            id="internctcAmount"
                            placeholder="Enter Amount"
                            value={internctcAmount}
                            onChange={handleInternCTCAmountChange}
                            max="5000000"
                            min="0"
                            style={{ width: '98%' }}
                            disabled={internctcType === "range" || !checkVisibility('43', 2) || jobPublished}
                          />
                          {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
                        </FormGroup>
                      </div>
                      <div className="col-md-6 row" style={{ marginTop: '0rem' }} >
                        <div className="col-md-5">
                          <FormGroup>
                            <Input
                              type="number"
                              name="text"
                              id="internctcMinAmount"
                              placeholder="Min Amount"
                              value={internctcRangeMin}
                              onChange={(event, oppID) => {
                                if (event.target.value > 5000000) {
                                  window.alert('Min CTC amount should not exceed 5000000')
                                } else {
                                  setInternCTCRangeMin(event.target.value)
                                }
                              }}
                              max="5000000"
                              min="0"
                              style={{ width: '98%' }}
                              disabled={internctcType === "amount" || !checkVisibility('43', 2) || jobPublished}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-5">
                          <FormGroup>
                            <Input
                              type="number"
                              name="text"
                              id="internctcMaxAmount"
                              placeholder="Max Amount"
                              value={internctcRangeMax}
                              onChange={(event, oppID) => {
                                if (event.target.value > 5000000) {
                                  window.alert('Max CTC amount should not exceed 5000000')
                                } else {
                                  setInternCTCRangeMax(event.target.value)
                                }
                              }}
                              max="5000000"
                              min={internctcRangeMin}
                              style={{ width: '98%' }}
                              disabled={internctcType === "amount" || !checkVisibility('43', 2) || jobPublished}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </li>

                  </>

                )}
                <li className='row'>
                  <div className="col-md-12">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Roles & Responsibilities
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Froala
                        // ref={ref}
                        model={roleData}
                        onModelChange={handleModelChange}
                        tag="textarea"
                        config={configs.editorConfig}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Number of Rounds
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="number"
                        name="text"
                        id="courseShortCode"
                        value={numRounds}
                        max="7"
                        min="0"
                        disabled={!checkVisibility('43', 2) || jobPublished}
                        onChange={(event, oppID) => {
                          setNumRounds(event.target.value)
                          let num = event.target.value
                          setSelectedRoundTypes((prevSelectedRoundTypes) => {
                            const newSelectedRoundTypes = [...prevSelectedRoundTypes];
                            // Add or remove dummy values
                            while (newSelectedRoundTypes.length < num) {
                              newSelectedRoundTypes.push({ value: roundTypes[0].value, label: roundTypes[0].label });
                            }
                            newSelectedRoundTypes.splice(num);
                            return newSelectedRoundTypes;
                          });
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                  {numRounds > 0 && (
                    <div className="col-md-6">
                      <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                        Select Round 1
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <Select
                          options={roundTypes}
                          placeholder="Select Round 1"
                          value={selectedRoundTypes[0] ? roundTypes[selectedRoundTypes[0].value] : null}
                          onChange={(selectedOption) => {
                            handleSelectedRoundTypeChange(0, selectedOption)
                          }}
                          isDisabled={!checkVisibility('43', 2) || jobPublished}
                          isSearchable={true}
                        />
                      </FormGroup>
                    </div>
                  )}
                </li>
                {Array.from({ length: parseInt(numRounds) - 1 }, (_, index) => (
                  <li className='row' key={index + 1}>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                      <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                        Select Round {index + 2}
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <Select
                          options={roundTypes}
                          placeholder={`Select Round ${index + 2}`}
                          value={selectedRoundTypes[index + 1] ? roundTypes[selectedRoundTypes[index + 1].value] : null}
                          onChange={(selectedOption) => {
                            handleSelectedRoundTypeChange(index + 1, selectedOption)
                          }}
                          isDisabled={!checkVisibility('43', 2) || jobPublished}
                          isSearchable={true}
                        />
                      </FormGroup>
                    </div>
                  </li>
                ))}
                <li className='row'>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Mode of Interview
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Select
                        options={interviewMode}
                        placeholder="Select Interview Mode"
                        value={selectedInterviewMode}
                        onChange={(event) => {
                          handleSelectedInterviewModeChange(event)
                        }}
                        isDisabled={!checkVisibility('43', 2) || jobPublished}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', paddingBottom: "8px" }}>
                      Select Job Location
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      {/* <Select
                        options={location}
                        placeholder="Select Location"
                        value={selectedLocation}
                        onChange={(event) => {
                          handleSelectedLocationChange(event)
                        }}
                        isDisabled={!checkVisibility('43', 2) || jobPublished}
                        isSearchable={true}
                        isMulti
                      /> */}
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={jobLocation}
                        onChange={(event, oppID) => {
                          const inputValue = event.target.value;
                        
                          if (inputValue.length > 50) {
                            alert('Input exceeds the maximum limit of 50 characters.');
                            return;
                          }
                        
                          setJobLocation(inputValue);
                        }}
                        
                        disabled={!checkVisibility('43', 2) || jobPublished}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-4">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      Does this Job include Bond?
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <div className='row' style={{ height: '60px' }}>
                      <FormControl component="fieldset" className='ctc-radio' style={{ width: '100%', flexDirection: 'row' }}>
                        <RadioGroup
                          aria-label="bondIncluded"
                          value={bondIncluded}
                          onChange={handleBondIncludedChange}
                          style={{ flexDirection: 'row', display: 'contents' }}
                        >
                          <FormControlLabel className="col-md-6" value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel className="col-md-6" value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </li>
                {(bondIncluded === "true" || bondIncluded === true) && (
                  <>
                    <li className='row'>
                      <div className="col-md-6">
                        <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select No. of Years For Contractual Period
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Input
                            type="number"
                            name="text"
                            id="courseShortCode"
                            max="5"
                            min="0"
                            value={bondDuration}
                            onChange={(event) => {
                              setBondDuration(event.target.value)
                            }}
                            disabled={!checkVisibility('43', 2) || jobPublished}
                            style={{ width: '98%' }}
                          />
                        </FormGroup>
                      </div>
                    </li>
                    <li className='row'>
                      <div className="col-md-12">
                        <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Remarks for Bond
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          {/* <CKEditor
                        type=""
                        name={"Bond Remarks"}
                        editor={ClassicEditor}
                        config={{
                          ckfinder: {
                            // Upload the images to the server using the CKFinder QuickUpload command
                            // You have to change this address to your server that has the ckfinder php connector
                            uploadUrl: "" //Enter your upload url
                          }
                        }}
                        data={bondData}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ event, editor, data })
                          setBondData(data)
                          // onChange(data);
                        }}
                      /> */}
                          <Froala
                            // ref={ref}
                            model={bondData}
                            onModelChange={handleBondDataChange}
                            tag="textarea"
                            config={configs.editorConfig}
                          />
                        </FormGroup>
                      </div>
                    </li>
                  </>
                )}

                {/* {block && renderAfterBlockSet()} */}
                <div className='addcrit-footer'>

                  <Row className="adques-footer">
                    <Col md={2} className="jobs-table-header-2">

                    </Col>
                    <Col md={5}></Col>
                    <Col md={2}>
                      {checkVisibility('43', 2) && (
                        <Button
                          className="labs-table-btn-2"
                          onClick={() => savePlacementJobDescriptionDraft()
                          }
                          disabled={jobPublished}
                        >
                          Save Draft
                        </Button>
                      )}
                    </Col>
                    <Col md={2}>
                      {!jobPublished ? (
                        <Button
                          className="jobs-table-btn-5"
                          onClick={() => {
                            handleSaveAndRedirect(jobid)
                          }}
                        >
                          Save & Next {">"}
                        </Button>) : (
                        <Button
                          className="jobs-table-btn-5"
                          onClick={() => {
                            history.push(`/career-module/add-job-criteria/${jobid}`)
                          }}
                        >
                          Next {">"}
                        </Button>
                      )}

                    </Col>
                  </Row>
                </div>
                <Modal
                  isOpen={openSuccessModal}
                  style={{ width: '20%' }}
                  size={"xl"}
                  className="apply-sucess-modal"
                  centered
                >
                  <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
                    <div>
                      <div className="prof-dtl-img ">
                      </div>
                      <p className="aply-success-txt">
                        Company Logo been successfully uploaded!
                      </p>
                      <p className="prof-dtl-txt">
                        It will be added when you click on save
                      </p>
                    </div>
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
                      {/* <Button
                    style={{ width: "48%",borderRadius:"4px"}}
                    onClick={() => {
                      setOpenSuccessModal(false);
                    }}
                    className="apply-success-btn"
                    color="danger"
                  >
                    Cancel
                  </Button> */}
                      <Button
                        onClick={() => {
                          setOpenSuccessModal(false);
                        }}
                        style={{ background: "#014644", width: "48%", borderRadius: "4px" }}
                      >
                        Done
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(CreateJobDescription)